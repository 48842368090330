import React, { useEffect, useState } from 'react'
import SoftBox from '../components/SoftBox'
import SoftTypography from '../components/SoftTypography'

import bg from "assets/images/bg_header.png";
import giona from "assets/images/giona-mini.png"
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { serverUrl } from 'global/variable';
import { Button, Card, Grid, Typography } from '@mui/material';
import userStore from 'store/userStore';
import Swal from 'sweetalert2';
import { CustomDescrypt } from 'global/func';
import { CustomEncrypt } from 'global/func';
import SoftInput from 'components/SoftInput';

const Home = () => {
    const navigate = useNavigate();
    const { user, setUser } = userStore((state) => ({ user: state.user, setUser: state.setUser }));
    const userData = localStorage.getItem('AuthPersist');
    const [formData, setFormData] = useState({
        username: '',
        password: '',
    })

    useEffect(() => {
        console.log(user);
        if (userData !== null) {
            const xx = JSON.parse(userData);

            if (xx.state.user !== null) {
                const decryptedUser = JSON.parse(CustomDescrypt(xx.state.user));

                // console.log(decryptedUser);

                if (decryptedUser.role === '1') {
                    navigate("/tim", { replace: true });
                }
                else if (decryptedUser.role === '2') {
                    navigate("/admin", { replace: true });
                }
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    const auth = () => {
        if (formData.username === "" || formData.password === "") {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: "Masukkan username dan password"
            })
            return
        }
        axios.post(serverUrl + '/auth', formData)
            .then(function (response) {
                console.log(response);
                if (response.status === 200) {
                    let data = response.data;
                    let encrypted = CustomEncrypt(JSON.stringify(data));
                    setUser(encrypted);
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response.data,
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.response.data,
                })
            });
    }

    return (
        <>
            {/* <SoftBox
                width="100vw"
                height="100%"
                minHeight="100vh"
                bgColor="white"
                sx={{ overflowX: "hidden" }}
            >
                <Grid
                    container
                    justifyContent="center"
                    sx={{
                        minHeight: "100vh",
                        margin: 0,
                    }}
                >
                    <Grid item xs={11} sm={8} md={5} xl={3}>
                        <SoftBox mt={20}>
                            <SoftBox pt={3} px={3}>
                                <SoftBox mb={1}>
                                    <SoftTypography variant="h3" fontWeight="bold" color="primary" textGradient>
                                        Welcome
                                    </SoftTypography>
                                </SoftBox>
                                <SoftTypography variant="body2" fontWeight="regular" color="text">
                                    Enter username and password to sign in
                                </SoftTypography>
                            </SoftBox>
                            <SoftBox p={3}>
                                <SoftBox component="form" role="form">
                                    <SoftBox mb={2}>
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Username
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput
                                            type="text"
                                            defaultValue={formData.username}
                                            onChange={(event) => setFormData({ ...formData, username: event.target.value })}
                                            autoComplete='off'
                                        />
                                    </SoftBox>

                                    <SoftBox mb={2}>
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Password
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput
                                            type="password"
                                            defaultValue={formData.password}
                                            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                                            autoComplete='off'
                                        />
                                    </SoftBox>
                                    <SoftBox mt={4} mb={1}>
                                        <Button variant="contained" color="primary" fullWidth onClick={auth}>
                                            sign in
                                        </Button>
                                    </SoftBox>
                                </SoftBox>
                            </SoftBox>
                        </SoftBox>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <SoftBox
                            height="100%"
                            display={{ xs: "none", md: "block" }}
                            position="relative"
                            right={{ md: "-12rem", xl: "-16rem" }}
                            mr={-16}
                            sx={{
                                // transform: "skewX(-10deg)",
                                overflow: "hidden",
                                borderBottomLeftRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
                            }}
                        >
                            <SoftBox
                                // ml={-8}
                                height="100%"
                                sx={{
                                    backgroundImage: `url(${bg})`,
                                    backgroundSize: "cover",
                                    transform: "skewX(10deg)",
                                }}
                            />
                        </SoftBox>
                    </Grid>
                </Grid>
            </SoftBox> */}

            <SoftBox position="relative" p={2}>
                <SoftBox
                    display="flex"
                    alignItems="center"
                    position="relative"
                    minHeight="32rem"
                    borderRadius="xl"
                    sx={{
                        backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
                            `${linearGradient(
                                rgba(gradients.info.main, 0),
                                rgba(gradients.info.state, 0)
                            )}, url(${bg})`,
                        backgroundSize: "cover",
                        backgroundPosition: "50%",
                        overflow: "hidden",
                    }}
                >
                    <Grid container justifyContent='center' sx={{ mt: -22 }}>
                        <Grid item md={4}>
                            <SoftBox textAlign="center">
                                <Typography variant="h1" fontWeight="bold" color="#0097a7" fontFamily='cursive'>
                                    Konsel Kampung Kita
                                </Typography>
                            </SoftBox>
                            {/* <SoftBox textAlign="center">
                                <Typography variant="h6" fontWeight="bold" color="#0097a7">
                                    Kendari Kota Cantik
                                </Typography>
                            </SoftBox> */}
                        </Grid>
                    </Grid>
                </SoftBox>
                <SoftBox sx={{ mt: -22 }}>
                    <Grid container spacing={3} alignItems="center" justifyContent='center'>
                        <Grid item xl={4} lg={6} md={9} >
                            <Card
                                sx={{
                                    backdropFilter: `saturate(200%) blur(30px)`,
                                    // backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
                                    boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
                                    // position: "relative",
                                    // mt: -22,
                                    mx: { md: 5, xs: 2 },
                                    py: 2,
                                }}
                            >
                                <SoftBox mt={2}>
                                    <SoftBox px={3}>
                                        <Grid container justifyContent='center'>
                                            <Grid item>
                                                <SoftBox mb={1}>
                                                    <SoftTypography variant="h3" fontWeight="bold" color="primary" textGradient textAlign="center">
                                                        Welcome
                                                    </SoftTypography>
                                                </SoftBox>
                                            </Grid>
                                            <Grid item>
                                                <SoftTypography variant="body2" fontWeight="regular" color="text">
                                                    Enter username and password to sign in
                                                </SoftTypography>
                                            </Grid>
                                        </Grid>
                                    </SoftBox>
                                    <SoftBox p={3}>
                                        <SoftBox component="form" role="form">
                                            <SoftBox mb={2} justifyContent="center">
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Username
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftInput
                                                    type="text"
                                                    defaultValue={formData.username}
                                                    onChange={(event) => setFormData({ ...formData, username: event.target.value })}
                                                    autoComplete='off'
                                                />
                                            </SoftBox>

                                            <SoftBox mb={2}>
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Password
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftInput
                                                    type="password"
                                                    defaultValue={formData.password}
                                                    onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                                                    autoComplete='off'
                                                />
                                            </SoftBox>
                                            <SoftBox mt={4} mb={1}>
                                                <Button variant="contained" color="primary" fullWidth onClick={auth}>
                                                    sign in
                                                </Button>
                                            </SoftBox>
                                        </SoftBox>
                                    </SoftBox>
                                </SoftBox>
                            </Card>
                        </Grid>
                    </Grid>
                </SoftBox>
            </SoftBox>
        </>

    )
}

export default Home