import { Grid } from '@mui/material'
import axios from 'axios'
import MiniStatisticsCard from 'components/Cards/StatisticsCards/MiniStatisticsCard'
import Headerx from 'components/Header/Headerx'
import SoftBox from 'components/SoftBox'
import { serverUrl } from 'global/variable'
import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import userStore from 'store/userStore'
import Swal from 'sweetalert2'
import MyTable from 'myComponent/Table/MyTable'
import EditDesa from './EditDesa'
import Detail from './Detail'
import TambahDesa from './TambahDesa'
import Edit from './Edit'
import { idKab } from 'global/variable'

const Data = () => {
    const logOut = userStore((state) => state.logOut)

    const [tableData, setTableData] = useState([])
    const [deleting, setDeleting] = useState(false)

    useEffect(() => {
        requestData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleting])

    // const [listKec, setListKec] = useState([]);

    // useEffect(() => {
    //     if (data.length > 0) {
    //         data.map((row) => (
    //             setListKec(prevState => [...prevState, row.nama_kec])
    //         ))
    //     }
    // }, [data])

    const columns = [
        //['Kecamatan', 'Jumlah DPT', 'DPT Terdata', 'Target KK', 'KK Terdata']
        //fields={['nama_kec', 'jml_dpt', 'jml_dpt_terdata', 'target', 'kk_terdata']}
        {
            accessorKey: 'id_kec',
            header: 'Id Kecamatan',
            // filterVariant: 'select',
            // filterSelectOptions: listKec
        },
        {
            accessorKey: 'nama_kec',
            header: 'Kecamatan',
            // filterVariant: 'select',
            // filterSelectOptions: listKec
        },
        {
            accessorKey: 'jml_dpt',
            header: 'Jumlah DPT'
        },
        {
            accessorKey: 'jml_dpt_terdata',
            header: 'DPT Terdata'
        },
        {
            accessorKey: 'target',
            header: 'Target KK'
        },
        {
            accessorKey: 'kk_terdata',
            header: 'KK Terdata'
        },
    ]

    const requestData = () => {
        axios.get(
            serverUrl + '/kecamatan/'+ idKab,

            // {
            //     headers: {
            //         'Authorization': `Bearer ${decryptedUser.token}`,
            //         'content-type': 'multipart/form-data'
            //     }
            // }
        )
            .then((response) => {
                // console.log(response);
                setTableData(response.data)
                // setTableLoading(false)
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                // setTableLoading(false)
            })
    }

    return (
        <SoftBox mb={3}>
            <Headerx title="Kecamatan" />
            <Grid container mt={3}>
                <Grid item xs={12}>
                    <MiniStatisticsCard
                        title={{ text: "Total Data" }}
                        count={tableData.length}
                        percentage={{ color: "success", text: "Kecamatan" }}
                        icon={{ color: "info", component: "paid" }}
                    />
                </Grid>
            </Grid>
            <Grid container justifyContent="space-between" mt={2}>
                <Grid item xs={12}>
                    {/* <TableData
                        tableData={tableData}
                        headers={['Kecamatan', 'Jumlah DPT', 'DPT Terdata', 'Target KK', 'KK Terdata']}
                        fields={['nama_kec', 'jml_dpt', 'jml_dpt_terdata', 'target', 'kk_terdata']}
                        deleting={deleting}
                        setDeleting={setDeleting}
                        deleteUrl={serverUrl + '/kecamatan/delete'}
                        deleteField='id_kec'
                        detailUrl={'/detail'}
                        editUrl={'/edit'}
                    /> */}

                    <MyTable
                        data={tableData}
                        columns={columns}
                        deleting={deleting}
                        setDeleting={setDeleting}
                        deleteUrl={serverUrl + '/kecamatan/delete'}
                        deleteField='id_kec'
                        detailUrl={'/detail'}
                        editUrl={'/edit'}
                    />
                </Grid>
            </Grid>
        </SoftBox>
    )

}

const Kecamatan = () => {
    return (
        <SoftBox py={3}>
            <Routes>
                <Route exact path='' element={<Data />} />
                <Route exact path='detail' element={<Detail />} />
                <Route exact path='edit' element={<Edit />} />
                <Route exact path='detail/editdesa' element={<EditDesa />} />
                <Route exact path='detail/tambahdesa' element={<TambahDesa />} />
            </Routes>
        </SoftBox>
    )
}

export default Kecamatan
