import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, Checkbox, FormControlLabel, FormGroup, Grid, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import dayjs from "dayjs";
import { CustomDescrypt } from "global/func";
import { serverUrl } from "global/variable";
import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import userStore from "store/userStore";
import Swal from "sweetalert2";
import Detail from "./Detail";
import SelectKab from "myComponent/SelectKab";
import SelectKec from "myComponent/SelectKec";
import SelectDesa from "myComponent/SelectDesa";
import PieStandar from "myComponent/Charts/PieStandar";
import Headerx from 'components/Header/Headerx';
import { ExpandMore, GroupAddOutlined, LocationOnOutlined, PeopleAltOutlined, ShareLocationOutlined } from "@mui/icons-material";
import InfoCard from "myComponent/Cards/InfoCard";
import StackedCollumn from "myComponent/Charts/StackedCollumn";
import MyTable from "myComponent/Table/MyTable";
import ColumnComparison from "myComponent/Charts/ColumnComparison";
import { idKab } from "global/variable";
import MapComponent from "myComponent/MapComponent";

const Pendataan = () => {
    return (
        <SoftBox py={3}>
            <Routes>
                <Route exact path='' element={<Data />} />
                <Route exact path='detail' element={<Detail />} />
                {/* <Route exact path='tambah' element={<Tambah />} />
                <Route exact path='edit' element={<Edit />} /> */}
            </Routes>
        </SoftBox>
    )
}

const Data = () => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const location = useLocation();
    const locationState = location.state

    const [tableData, setTableData] = useState([]);
    const tanggal = dayjs(new Date());
    const [deleting, setDeleting] = useState(false)
    const [bpjsPie, setBpjsPie] = useState([])
    const [bpjsStackCol, setBpjsStackCol] = useState([])
    const [plnPie, setPlnPie] = useState([])
    const [plnStackCol, setPlnStackCol] = useState([])
    const [pdamPie, setPdamPie] = useState([])
    const [pdamStackCol, setPdamStackCol] = useState([])
    const [bantuanPie, setBantuanPie] = useState([])
    const [bantuanStackCol, setBantuanStackCol] = useState([])
    const [tableLoading, setTableLoading] = useState(true)
    const [kecs, setKecs] = useState([])
    const [desas, setDesas] = useState([])

    const [formData, setFormData] = useState({
        semua: true,
        tanggal: tanggal,
        id_kab: idKab,
        id_kec: locationState !== null ? locationState : '',
        id_desa: ''
    })

    useEffect(() => {
        requestData()
        getDataPieChartBpjs()
        getDataStackedColumnsBpjs()
        getDataPieChartPln()
        getDataStackedColumnsPln()
        getDataPieChartPdam()
        getDataStackedColumnsPdam()
        getDataPieChartBantuan()
        getDataStackedColumnsBantuan()
        console.log(formData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData, deleting])

    const getDataPieChartBpjs = () => {
        axios.post(
            serverUrl + '/admin/pie',
            {
                semua: formData.semua,
                tgl: formData.tanggal.format('YYYY-MM-DD'),
                id_kab: idKab,
                id_kec: formData.id_kec,
                id_desa: formData.id_desa,
                categories: ['-', '1', '2', '3'],
                names: ['Tidak Ada BPJS', 'Kelas 1', 'Kelas 2', 'Kelas 3'],
                field: 'kelas_bpjs'
            },
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser.token}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                // console.log(response);
                setBpjsPie(response.data)
                // setTableLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                // setTableLoading(false)
            })
    }

    const getDataStackedColumnsBpjs = () => {
        axios.post(
            serverUrl + '/admin/stackedcolumns',
            {
                semua: formData.semua,
                tgl: formData.tanggal.format('YYYY-MM-DD'),
                id_kab: idKab,
                id_kec: formData.id_kec,
                id_desa: formData.id_desa,
                categories: ['-', '1', '2', '3'],
                names: ['Tidak Ada BPJS', 'Kelas 1', 'Kelas 2', 'Kelas 3'],
                field: 'kelas_bpjs'
            },
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser.token}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                // console.log(response);
                setBpjsStackCol(response.data)
                // setTableLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                // setTableLoading(false)
            })
    }

    const getDataPieChartPln = () => {
        axios.post(
            serverUrl + '/admin/pie',
            {
                semua: formData.semua,
                tgl: formData.tanggal.format('YYYY-MM-DD'),
                id_kab: idKab,
                id_kec: formData.id_kec,
                id_desa: formData.id_desa,
                categories: ['-', '450', '900'],
                names: ['Tidak ada Listrik', '450 WATT', '900 WATT'],
                field: 'pln'
            },
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser.token}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                // console.log(response);
                setPlnPie(response.data)
                // setTableLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                // setTableLoading(false)
            })
    }

    const getDataStackedColumnsPln = () => {
        axios.post(
            serverUrl + '/admin/stackedcolumns',
            {
                semua: formData.semua,
                tgl: formData.tanggal.format('YYYY-MM-DD'),
                id_kab: idKab,
                id_kec: formData.id_kec,
                id_desa: formData.id_desa,
                categories: ['-', '450', '900'],
                names: ['Tidak ada Listrik', '450 WATT', '900 WATT'],
                field: 'pln'
            },
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser.token}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                // console.log(response);
                setPlnStackCol(response.data)
                // setTableLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                // setTableLoading(false)
            })
    }

    const getDataPieChartPdam = () => {
        axios.post(
            serverUrl + '/admin/pie',
            {
                semua: formData.semua,
                tgl: formData.tanggal.format('YYYY-MM-DD'),
                id_kab: idKab,
                id_kec: formData.id_kec,
                id_desa: formData.id_desa,
                categories: ['-', 'Ya'],
                names: ['Bukan Pengguna PDAM', 'Pengguna PDAM'],
                field: 'pengguna_pdam'
            },
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser.token}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                // console.log(response);
                setPdamPie(response.data)
                // setTableLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                // setTableLoading(false)
            })
    }

    const getDataStackedColumnsPdam = () => {
        axios.post(
            serverUrl + '/admin/stackedcolumns',
            {
                semua: formData.semua,
                tgl: formData.tanggal.format('YYYY-MM-DD'),
                id_kab: idKab,
                id_kec: formData.id_kec,
                id_desa: formData.id_desa,
                categories: ['-', 'Ya'],
                names: ['Bukan Pengguna PDAM', 'Pengguna PDAM'],
                field: 'pengguna_pdam'
            },
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser.token}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                // console.log(response);
                setPdamStackCol(response.data)
                // setTableLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                // setTableLoading(false)
            })
    }

    const getDataPieChartBantuan = () => {
        axios.post(
            serverUrl + '/admin/pie-bantuan',
            {
                semua: formData.semua,
                tgl: formData.tanggal.format('YYYY-MM-DD'),
                id_kab: idKab,
                id_kec: formData.id_kec,
                id_desa: formData.id_desa
            },
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser.token}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                // console.log(response);
                setBantuanPie(response.data)
                // setTableLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                // setTableLoading(false)
            })
    }

    const getDataStackedColumnsBantuan = () => {
        axios.post(
            serverUrl + '/admin/stackedcolumns-bantuan',
            {
                semua: formData.semua,
                tgl: formData.tanggal.format('YYYY-MM-DD'),
                id_kab: idKab,
                id_kec: formData.id_kec,
                id_desa: formData.id_desa,
            },
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser.token}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                // console.log(response);
                setBantuanStackCol(response.data)
                // setTableLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                // setTableLoading(false)
            })
    }

    const requestData = () => {
        setTableLoading(true)
        const form = {
            semua: formData.semua,
            tgl: formData.tanggal.format('YYYY-MM-DD'),
            kab: formData.id_kab,
            kec: formData.id_kec,
            desa: formData.id_desa
        }
        axios.post(
            serverUrl + '/admin/pendataan',
            form,
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser.token}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                // console.log(response);
                setTableData(response.data)
                setTableLoading(false)
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                setTableLoading(false)
            })
    }

    const columns = [
        //fields = { ['no_kk', 'nik_kepala', 'nama_kepala', 'pekerjaan', 'jml_anggota', 'no_hp', 'created_at', 'nama_petugas']}
        //headers = { ['No KK', 'NIK KK', 'Nama KK', 'Pekerjaan', 'Jml Anggota', 'No. HP', 'Jam Input', 'Tim']}
        {
            accessorKey: 'no_kk',
            header: 'No KK',
            filterVariant: 'text',
            // filterVariant: 'select',
            // filterSelectOptions: listKec
        },
        {
            accessorKey: 'nik_kepala',
            header: 'NIK KK DPT'
        },
        {
            accessorKey: 'nama_kepala',
            header: 'Nama KK'
        },
        {
            accessorKey: 'id_kab',
            header: 'ID_KAB'
        },
        {
            accessorKey: 'nama_kab',
            header: 'KAB'
        },
        {
            accessorKey: 'id_kec',
            header: 'ID_KEC'
        },
        {
            accessorKey: 'nama_kec',
            header: 'KEC'
        },
        {
            accessorKey: 'id_desa',
            header: 'ID_KELDESA'
        },
        {
            accessorKey: 'nama_desa',
            header: 'KEL/DESA'
        },
        {
            accessorKey: 'nama_dusun',
            header: 'RT/RW/DUS'
        },
        {
            accessorKey: 'alamat',
            header: 'Alamat'
        },
        {
            accessorKey: 'pekerjaan',
            header: 'Pekerjaan'
        },
        {
            accessorKey: 'no_hp',
            header: 'No. HP'
        },
        {
            accessorKey: 'jml_anggota',
            header: 'Jml Anggota'
        },
        {
            accessorKey: 'pengguna_pdam',
            header: 'PDAM'
        },
        {
            accessorKey: 'pemakaian_pdam',
            header: 'PDAM (Rp)'
        },
        {
            accessorKey: 'pln',
            header: 'PLN'
        },
        {
            accessorKey: 'no_pln',
            header: 'No. PLN'
        },
        {
            accessorKey: 'kelas_bpjs',
            header: 'Kelas BPJS'
        },
        {
            accessorKey: 'suku',
            header: 'Suku'
        },
        {
            accessorKey: 'bantuan',
            header: 'Bantuan'
        },
        {
            accessorKey: 'created_at',
            header: 'Jam Input'
        },
        {
            accessorKey: 'nama_petugas',
            header: 'Tim'
        },
    ]

    const columnVisibility = {
        id_kab: false,
        nama_kab: false,
        id_kec: false,
        nama_kec: false,
        id_desa: false,
        nama_desa: false,
        nama_dusun: false,
        alamat: false
    }

    return (
        <SoftBox mb={3}>
            <Headerx title="Pendataan" />

            <Grid container spacing={2} mt={2}>
                <Grid item xs={12} lg={3}>
                    <InfoCard
                        title={{ text: "Kecamatan" }}
                        icon={<LocationOnOutlined fontSize='medium' color='warning' sx={{ opacity: 0.3, fontSize: 90 }} />}
                    />
                </Grid>
                <Grid item xs={12} lg={3}>
                    <InfoCard
                        title={{ text: "Kelurahan" }}
                        icon={<ShareLocationOutlined fontSize='medium' sx={{ opacity: 0.3, fontSize: 90, color: '#0097a7' }} />}
                        count={65}
                    />
                </Grid>
                <Grid item xs={12} lg={3}>
                    <InfoCard
                        title={{ text: "Tim Pendataan" }}
                        icon={<PeopleAltOutlined fontSize='medium' sx={{ opacity: 0.3, fontSize: 90 }} color='error' />}
                        count={62}
                    />
                </Grid>
                <Grid item xs={12} lg={3}>
                    <InfoCard
                        title={{ text: "Jumlah Data" }}
                        icon={<GroupAddOutlined fontSize='medium' sx={{ opacity: 0.3, fontSize: 90 }} color='success' />}
                        count={tableData.length}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} pt={2}>
                <Grid item xs={12} lg={12}>
                    <Card >
                        <CardContent sx={{ mt: 2 }}>
                            <MapComponent data={tableData} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={0}>
                <Grid item xs={12} lg={12}>
                    {/* <StackedCollumn title='Pendataan KK' series={bpjsStackCol.series} categories={categories} /> */}
                    <ColumnComparison title='Pendataan KK' desas={desas} kecs={kecs} />
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={0}>
                <Grid item xs={12} lg={12}>
                    <Accordion>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMore />}>
                            <Typography>BPJS</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <Grid item xs={12} lg={6}>
                                    <PieStandar title="Pengguna BPJS" data={bpjsPie} />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <StackedCollumn title='Pengguna BPJS' series={bpjsStackCol.series} categories={bpjsStackCol.categories} />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={0}>
                <Grid item xs={12} lg={12}>
                    <Accordion>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMore />}>
                            <Typography>PLN</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2} mt={0}>
                                <Grid item xs={12} lg={6}>
                                    <StackedCollumn title='PLN' series={plnStackCol.series} categories={plnStackCol.categories} />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <PieStandar title="PLN" data={plnPie} />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={0}>
                <Grid item xs={12} lg={12}>
                    <Accordion>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMore />}>
                            <Typography>PDAM</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2} mt={0}>
                                <Grid item xs={12} lg={6}>
                                    <Card sx={{ p: 1 }}>
                                        <PieStandar title="PDAM" data={pdamPie} />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <Card sx={{ p: 1 }}>
                                        <StackedCollumn title='Pengguna PDAM' series={pdamStackCol.series} categories={pdamStackCol.categories} />
                                    </Card>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={0}>
                <Grid item xs={12} lg={12}>
                    <Accordion>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMore />}>
                            <Typography>Bantuan</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2} mt={0}>
                                <Grid item xs={12} lg={7}>
                                    <Card sx={{ p: 1 }}>
                                        <StackedCollumn title='Bantuan' series={bantuanStackCol.series} categories={bantuanStackCol.categories} />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} lg={5}>
                                    <Card sx={{ p: 1 }}>
                                        <PieStandar title="Bantuan" data={bantuanPie} />
                                    </Card>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
            
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12}>
                    <Card variant='outlined' spacing={3}>
                        <Grid container justifyContent="space-between" p={2}>
                            <Grid item xs="auto">
                                <Typography>
                                    Tabel Pendataan
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container px={2} spacing={2} alignItems='flex-end'>
                            <Grid item xs={12} lg={4}>
                                <SoftBox mb={1}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        Pilih Tanggal
                                    </SoftTypography>
                                </SoftBox>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker variant='outlined' format='YYYY-MM-DD' value={formData.tanggal} onChange={(newValue) => setFormData({ ...formData, tanggal: newValue })} disabled={formData.semua} sx={{ width: '100% !important' }} />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} lg={4} ml={1.5}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={formData.semua} onChange={(event) => setFormData({ ...formData, semua: event.target.checked })} />} label="Semua Tanggal" />
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <Grid container px={2} spacing={2}>
                            <Grid item xs={12} lg={4}>
                                {/* <InputLabel id="demo-simple-select-label">Pilih Kabupaten</InputLabel> */}
                                <SelectKab formData={formData} setFormData={setFormData} disabled={true} />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <SelectKec formData={formData} setFormData={setFormData} idKab={formData.id_kab} setKecs={setKecs} />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <SelectDesa formData={formData} setFormData={setFormData} idKec={formData.id_kec} setDesass={setDesas} />
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="space-between" p={2}>
                            <Grid item xs={12}>
                                <MyTable
                                    data={tableData ?? []}
                                    // headers={['No KK', 'NIK KK', 'Nama KK', 'Pekerjaan', 'Jml Anggota', 'No. HP', 'Jam Input', 'Tim']}
                                    // fields={['no_kk', 'nik_kepala', 'nama_kepala', 'pekerjaan', 'jml_anggota', 'no_hp', 'created_at', 'nama_petugas']}
                                    columns={columns}
                                    deleting={deleting}
                                    setDeleting={setDeleting}
                                    columnVisibility={columnVisibility}
                                    deleteUrl={serverUrl + '/admin/pendataan/delete'}
                                    deleteField='no_kk'
                                    detailUrl={'/detail'}
                                    state={{ isLoading: tableLoading }}
                                />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </SoftBox>
    )
}

export default Pendataan