import React, { useEffect, useState } from 'react'
import SoftBox from '../../components/SoftBox'
import { Card, CardContent, Grid, Typography } from '@mui/material'
import Headerx from 'components/Header/Headerx';
import InfoCard from 'myComponent/Cards/InfoCard';
import { GroupAddOutlined, PeopleAltOutlined, PersonAddAltOutlined } from '@mui/icons-material';
import BarDrilldown from 'myComponent/Charts/BarDrilldown';
import ActivityGaugeChart from 'myComponent/Charts/ActivityGaugeChart';
import PieProgres from 'myComponent/Charts/PieProgres';
import MapComponent from 'myComponent/MapComponent';
import { CustomDescrypt } from 'global/func';
import userStore from 'store/userStore';
import axios from 'axios';
import { serverUrl } from 'global/variable';
import Swal from 'sweetalert2';
import PieStandar from 'myComponent/Charts/PieStandar';
import StackedCollumn from 'myComponent/Charts/StackedCollumn';
import { Link } from 'react-router-dom';
import { idKab } from 'global/variable';
import MapChart from 'myComponent/Charts/MapChart';

const Dashboard = () => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    // const [data, setData] = useState([])
    const [bpjsPie, setBpjsPie] = useState([])
    const [bpjsStackCol, setBpjsStackCol] = useState([])
    const [plnPie, setPlnPie] = useState([])
    const [plnStackCol, setPlnStackCol] = useState([])
    const [pdamPie, setPdamPie] = useState([])
    const [pdamStackCol, setPdamStackCol] = useState([])
    const [bantuanPie, setBantuanPie] = useState([])
    const [bantuanStackCol, setBantuanStackCol] = useState([])
    const [barDrilldown, setBarDrilldown] = useState([])
    const [barSeries, setBarSeries] = useState([])
    const [kecs, setKecs] = useState([])
    const [target, setTarget] = useState(0)
    const [dpt, setDpt] = useState(0)
    const [terdata, setTerdata] = useState(0)
    const [terdataDpt, setTerdataDpt] = useState(0)

    useEffect(() => {
        // reqDataPendataan()
        getDataPieChartBpjs()
        getDataStackedColumnsBpjs()
        getDataPieChartPln()
        getDataStackedColumnsPln()
        getDataPieChartPdam()
        getDataStackedColumnsPdam()
        getDataPieChartBantuan()
        getDataStackedColumnsBantuan()
        getBarDrilldown()
        reqKec()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        let trg = 0;
        let dp = 0;
        let ter = 0;
        let terDp = 0;

        kecs.forEach(data => {
            trg = trg + data.target;
            dp += data.jml_dpt;
            ter += data.kk_terdata
            terDp += data.jml_dpt_terdata
        });

        setTarget(trg)
        setDpt(dp)
        setTerdata(ter)
        setTerdataDpt(terDp)
        console.log(terdataDpt);
    }, [kecs])

    const reqKec = () => {
        axios.get(
            serverUrl + '/kecamatan/' + idKab,

            // {
            //     headers: {
            //         'Authorization': `Bearer ${decryptedUser.token}`,
            //         'content-type': 'multipart/form-data'
            //     }
            // }
        )
            .then((response) => {
                // console.log(response);
                setKecs(response.data)
                // setTableLoading(false)
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                // setTableLoading(false)
            })
    }

    // const reqDataPendataan = () => {
    //     axios.post(
    //         serverUrl + '/admin/pendataan',
    //         {
    //             semua: true
    //         },
    //         {
    //             headers: {
    //                 'Authorization': `Bearer ${decryptedUser.token}`,
    //                 'content-type': 'multipart/form-data'
    //             }
    //         })
    //         .then((response) => {
    //             // console.log(response);
    //             setData(response.data)
    //             // setTableLoading(false)
    //         })
    //         .catch((error) => {
    //             if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
    //                 logOut();
    //             }
    //             else {
    //                 Swal.fire({
    //                     icon: 'error',
    //                     title: 'Oops...',
    //                     text: error.response.status + ' ' + error.response.statusText
    //                 })
    //             }
    //             // setTableLoading(false)
    //         })
    // }

    const getDataPieChartBpjs = () => {
        axios.post(
            serverUrl + '/admin/pie',
            {
                semua: true,
                id_kab: idKab,
                id_kec: '',
                id_desa: '',
                categories: ['-', '1', '2', '3'],
                names: ['Tidak Ada BPJS', 'Kelas 1', 'Kelas 2', 'Kelas 3'],
                field: 'kelas_bpjs'
            },
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser.token}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                console.log(response);
                setBpjsPie(response.data)
                // setTableLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                // setTableLoading(false)
            })
    }

    const getDataStackedColumnsBpjs = () => {
        axios.post(
            serverUrl + '/admin/stackedcolumns',
            {
                semua: true,
                id_kab: idKab,
                id_kec: '',
                id_desa: '',
                categories: ['-', '1', '2', '3'],
                names: ['Tidak Ada BPJS', 'Kelas 1', 'Kelas 2', 'Kelas 3'],
                field: 'kelas_bpjs'
            },
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser.token}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                console.log(response);
                setBpjsStackCol(response.data)
                // setTableLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                // setTableLoading(false)
            })
    }

    const getDataPieChartPln = () => {
        axios.post(
            serverUrl + '/admin/pie',
            {
                semua: true,
                id_kab: idKab,
                id_kec: '',
                id_desa: '',
                categories: ['-', '450', '900'],
                names: ['Tidak ada Listrik', '450 WATT', '900 WATT'],
                field: 'pln'
            },
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser.token}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                console.log(response);
                setPlnPie(response.data)
                // setTableLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                // setTableLoading(false)
            })
    }

    const getDataStackedColumnsPln = () => {
        axios.post(
            serverUrl + '/admin/stackedcolumns',
            {
                semua: true,
                id_kab: idKab,
                id_kec: '',
                id_desa: '',
                categories: ['-', '450', '900'],
                names: ['Tidak ada Listrik', '450 WATT', '900 WATT'],
                field: 'pln'
            },
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser.token}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                console.log(response);
                setPlnStackCol(response.data)
                // setTableLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                // setTableLoading(false)
            })
    }

    const getDataPieChartPdam = () => {
        axios.post(
            serverUrl + '/admin/pie',
            {
                semua: true,
                id_kab: idKab,
                id_kec: '',
                id_desa: '',
                categories: ['-', 'Ya'],
                names: ['Bukan Pengguna PDAM', 'Pengguna PDAM'],
                field: 'pengguna_pdam'
            },
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser.token}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                // console.log(response);
                setPdamPie(response.data)
                // setTableLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                // setTableLoading(false)
            })
    }

    const getDataStackedColumnsPdam = () => {
        axios.post(
            serverUrl + '/admin/stackedcolumns',
            {
                semua: true,
                id_kab: idKab,
                id_kec: '',
                id_desa: '',
                categories: ['-', 'Ya'],
                names: ['Bukan Pengguna PDAM', 'Pengguna PDAM'],
                field: 'pengguna_pdam'
            },
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser.token}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                console.log(response);
                setPdamStackCol(response.data)
                // setTableLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                // setTableLoading(false)
            })
    }

    const getDataPieChartBantuan = () => {
        axios.post(
            serverUrl + '/admin/pie-bantuan',
            {
                semua: true,
                id_kab: idKab,
                id_kec: '',
                id_desa: ''
            },
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser.token}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                // console.log(response);
                setBantuanPie(response.data)
                // setTableLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                // setTableLoading(false)
            })
    }

    const getDataStackedColumnsBantuan = () => {
        axios.post(
            serverUrl + '/admin/stackedcolumns-bantuan',
            {
                semua: true,
                id_kab: idKab,
                id_kec: '',
                id_desa: ''
            },
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser.token}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                console.log(response);
                setBantuanStackCol(response.data)
                // setTableLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                // setTableLoading(false)
            })
    }

    const getBarDrilldown = (id_kab) => {
        axios.post(
            serverUrl + '/admin/bar-drilldown',
            {
                id_kab: idKab
            },
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser.token}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                console.log(response);
                setBarDrilldown(response.data.drilldown)
                setBarSeries(response.data.series)
                // setTableLoading(false)
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                // setTableLoading(false)
            })
    }

    return (
        <>
            <SoftBox>
                <Headerx title="Dashboard" />
                <SoftBox py={3}>
                    <Grid container spacing={2} alignItems="stretch">
                        <Grid item xs={12} sm={12} xl={8}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={4}>
                                    <InfoCard
                                        title={{ text: "Target Pendataan" }}
                                        icon={<PeopleAltOutlined fontSize='medium' color='warning' sx={{ opacity: 0.3, fontSize: 90 }} />}
                                        count={target.toLocaleString()}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <InfoCard
                                        title={{ text: "Terdata (KK)" }}
                                        icon={<GroupAddOutlined fontSize='medium' sx={{ opacity: 0.3, fontSize: 90, color: '#0097a7' }} />}
                                        count={terdata.toLocaleString()}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <InfoCard
                                        title={{ text: "Terdata (DPT)" }}
                                        icon={<PersonAddAltOutlined fontSize='medium' sx={{ opacity: 0.3, fontSize: 90 }} color='error' />}
                                        count={terdataDpt.toLocaleString()}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} pt={2}>
                                <Grid item xs={12} lg={12}>
                                    <Card sx={{ p: 1 }}>
                                        <MapChart kecamatans={kecs} />
                                    </Card>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} pt={2}>
                                <Grid item xs={12} lg={12}>
                                    <Card sx={{ p: 1 }}>
                                        <BarDrilldown series={barSeries} drilldown={barDrilldown} />
                                    </Card>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} pt={2}>
                                {
                                    bpjsPie.map((data) => (
                                        <Grid item xs={12} md={6} key={data.name}>
                                            <Card>
                                                <Grid container alignItems='center' justifyContent="space-around" direction="row" px={2}>
                                                    <Grid item xs={9}>
                                                        <Typography variant='h5' fontWeight='700'>
                                                            {data.name === 'Tidak Ada' ? 'Tidak ada BPJS' : 'BPJS ' + data.name}
                                                        </Typography>
                                                        <Typography variant='body2' color='GrayText'>
                                                            {data.y} KK
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3} textAlign='center' pt={1}>
                                                        <PieProgres value={data.persen} color={data.color} />
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                        </Grid>
                                    ))
                                }
                                <Grid item xs={12}>
                                    <Card sx={{ p: 1 }}>
                                        <PieStandar title="Pengguna BPJS" data={bpjsPie} />
                                    </Card>
                                </Grid>
                            </Grid>
                            <Grid container pt={2}>
                                <Grid item xs={12}>
                                    <Card sx={{ p: 1 }}>
                                        <StackedCollumn title='Pengguna BPJS' series={bpjsStackCol.series} categories={bpjsStackCol.categories} />
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} xl={4}>
                            <Grid container maxHeight={2030} sx={{ overflowY: 'scroll' }} spacing={2}>
                                <Grid item xs={12}>
                                    <Card sx={{ p: 1 }}>
                                        <ActivityGaugeChart
                                            title='Kota Kendari'
                                            exporting={true}
                                            tooltip={true}
                                            totalDpt={dpt}
                                            dptTerdata={terdataDpt}
                                            target={target}
                                            kkTerdata={terdata}
                                        />
                                        <Grid container spacing={0}>
                                            <Grid item xs={4} textAlign='center'>
                                                <Typography variant='caption' color='GrayText'>
                                                    Total DPT
                                                </Typography>
                                                <Typography variant='h6' fontWeight='700'>
                                                    {dpt.toLocaleString()}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4} textAlign='center'>
                                                <Typography variant='caption' color='GrayText'>
                                                    Target
                                                </Typography>
                                                <Typography variant='h6' fontWeight='700'>
                                                    {target.toLocaleString()}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4} textAlign='center'>
                                                <Typography variant='caption' color='GrayText'>
                                                    Tercapai
                                                </Typography>
                                                <Typography variant='h6' fontWeight='700'>
                                                    {terdata.toLocaleString()}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                                {
                                    kecs.map((kec) => (
                                        <Grid item xs={12} key={kec.id_kec}>
                                            <Card>
                                                <Grid container alignItems='center' justifyContent="space-around" direction="row" px={2} component={Link} state={kec.id_kec} to={'/admin/pendataan'}>
                                                    <Grid item xs={9}>
                                                        <Typography variant='h5' fontWeight='700'>
                                                            {kec.nama_kec}
                                                        </Typography>
                                                        <Typography variant='body2' color='GrayText'>
                                                            Terdata {kec.kk_terdata} KK (Target {kec.target} KK)
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3} textAlign='center' pt={1}>
                                                        <PieProgres value={kec.kk_terdata === 0 ? 0 : (kec.kk_terdata / kec.target)} color={kec.color} />
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container pt={2} spacing={2} alignItems='stretch'>
                        {
                            plnPie.map((data) => (
                                <Grid item xs={12} md={4} key={data.name}>
                                    <Card>
                                        <Grid container alignItems='center' justifyContent="space-around" direction="row" px={2}>
                                            <Grid item xs={9}>
                                                <Typography variant='h5' fontWeight='700'>
                                                    {data.name === 'Tidak Ada' ? 'Tidak ada' : data.name}
                                                </Typography>
                                                <Typography variant='body2' color='GrayText'>
                                                    {data.y} KK
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3} textAlign='center' pt={1}>
                                                <PieProgres value={data.persen} color={data.color} />
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                            ))
                        }
                        <Grid item xs={12} lg={5}>
                            <Card sx={{ p: 1 }}>
                                <PieStandar title="PLN" data={plnPie} />
                            </Card>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <Card sx={{ height: '100%', p: 1 }}>
                                <StackedCollumn title='Pengguna PLN' series={plnStackCol.series} categories={plnStackCol.categories} />
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} pt={2}>
                        {
                            pdamPie.map((data) => (
                                <Grid item xs={12} md={6} key={data.name}>
                                    <Card>
                                        <Grid container alignItems='center' justifyContent="space-around" direction="row" px={2}>
                                            <Grid item xs={9}>
                                                <Typography variant='h5' fontWeight='700'>
                                                    {data.name === 'Tidak Ada' ? 'Tidak ada' : data.name}
                                                </Typography>
                                                <Typography variant='body2' color='GrayText'>
                                                    {data.y} KK
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3} textAlign='center' pt={1}>
                                                <PieProgres value={data.persen} color={data.color} />
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                            ))
                        }
                        <Grid item xs={12} lg={8}>
                            <Card sx={{ p: 1 }}>
                                <StackedCollumn title='Pengguna PDAM' series={pdamStackCol.series} categories={pdamStackCol.categories} />
                            </Card>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Card sx={{ height: '100%', p: 1, verticalAlign: 'midle' }}>
                                <PieStandar title="Pengguna PDAM" data={pdamPie} />
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} pt={2}>
                        {
                            bantuanPie.map((data) => (
                                <Grid item xs={12} md={4} key={data.name}>
                                    <Card>
                                        <Grid container alignItems='center' justifyContent="space-around" direction="row" px={2}>
                                            <Grid item xs={9}>
                                                <Typography variant='h5' fontWeight='700'>
                                                    {data.name === 'Tidak Ada' ? 'Tidak ada' : data.name}
                                                </Typography>
                                                <Typography variant='body2' color='GrayText'>
                                                    {data.y} KK
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3} textAlign='center' pt={1}>
                                                <PieProgres value={data.persen} color={data.color} />
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                            ))
                        }
                        <Grid item xs={12} lg={6}>
                            <Card sx={{ p: 1 }}>
                                <PieStandar title="Bantuan" data={bantuanPie} />
                            </Card>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Card sx={{ p: 1 }}>
                                <StackedCollumn title='Bantuan' series={bantuanStackCol.series} categories={bantuanStackCol.categories} />
                            </Card>
                        </Grid>
                    </Grid>
                    {/* <Grid container spacing={2} pt={2}>
                        <Grid item xs={12} lg={12}>
                            <Card >
                                <CardContent sx={{ mt: 2 }}>
                                    <MapComponent data={data} />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid> */}
                    
                </SoftBox>
            </SoftBox>
        </>
    )
}

export default Dashboard