import { Home, LocationOn, Menu, Person } from "@mui/icons-material";

const adminRoutes = [
    {
        type: "collapse",
        name: "Dashboard",
        key: [''],
        route: "/admin",
        icon: <Home size="12px" />,
        noCollapse: true,
    },
    {
        type: "collapse",
        name: "Pendataan",
        key: ['pendataan'],
        route: "/admin/pendataan",
        icon: <Menu size="12px" />,
        noCollapse: true,
    },
    {
        type: "collapse",
        name: "User",
        key: ['tim', 'admin'],
        icon: <Person size="12px" />,
        collapse: [
            {
                name: "Tim",
                key: ["/admin/tim", "/admin/tim/tambah", "/admin/tim/edit", "/admin/tim/detail", "/admin/tim/detail/detail-keluarga"],
                route: "/admin/tim"
            },
            {
                name: "Admin",
                key: ["/admin/admin", "/admin/admin/tambah", "/admin/admin/edit"],
                route: "/admin/admin"
            }
        ],
    },
    {
        type: "collapse",
        name: "Kecamatan",
        key: ['kecamatan'],
        route: "/admin/kecamatan",
        icon: <LocationOn size="12px" />,
        noCollapse: true,
    },
];

export default adminRoutes;
