import { ArrowBack, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Card, CardContent, CardHeader, Grid, IconButton, Tooltip } from '@mui/material';
import axios from 'axios';
import Headerx from 'components/Header/Headerx';
import SoftBox from 'components/SoftBox';
import SoftInput from 'components/SoftInput';
import SoftTypography from 'components/SoftTypography';
import { CustomDescrypt } from 'global/func';
import { serverUrl } from 'global/variable';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import userStore from 'store/userStore';
import Swal from 'sweetalert2';

const Tambah = () => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        nama: '',
    })

    const submit = async (event) => {
        event.preventDefault();
        axios.post(
            serverUrl + '/admin/admin/tambah',
            formData,
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser.token}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                console.log(response);
                Swal.fire({
                    icon: 'success',
                    title: 'Berhasil!',
                    text: response.data.msg
                })
                // navigate(-1)
                navigate(-1)
                setLoading(false)
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText + ' ' + error.response.data.messages.error

                    })
                }
                setLoading(false)
            })
    }
    return (
        <>
            <Headerx title="Tambah Data" />
            <SoftBox mb={3}>
                <Grid container spacing={3} justifyContent='center' pt={5}>
                    <Grid item xs={12} sm={12} md={9}>
                        <Card >
                            <CardHeader
                                title={
                                    <SoftTypography variant='h6'>Kembali</SoftTypography>
                                }
                                avatar={
                                    <Tooltip title="Kembali">
                                        <IconButton variant="outlined" onClick={() => navigate(-1)}><ArrowBack /></IconButton>
                                    </Tooltip>
                                }
                            // subheader="September 14, 2016"
                            />
                            <CardContent component='form' onSubmit={submit}>
                                <Grid container spacing={3} justifyContent="center">
                                    <Grid item xs={12} sm={4}>
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Username
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="text" defaultValue={formData.username} onChange={(event) => setFormData({ ...formData, username: event.target.value })} required />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Password
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="text" defaultValue={formData.password} onChange={(event) => setFormData({ ...formData, password: event.target.value })} required />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Nama
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="text" defaultValue={formData.nama} onChange={(event) => setFormData({ ...formData, nama: event.target.value })} required />
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent='flex-end' pt={3}>
                                    <Grid item>
                                        <SoftBox mb={1} ml={0.5}>
                                            <LoadingButton
                                                loading={loading}
                                                loadingPosition="start"
                                                startIcon={<Save />}
                                                variant="contained"
                                                type='submit'
                                            >
                                                Save
                                            </LoadingButton>
                                        </SoftBox>

                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </SoftBox>
        </>
    )
}

export default Tambah

