import { AddCircleOutline, ArrowBack } from '@mui/icons-material'
import { Button, Card, CardContent, CardHeader, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import axios from 'axios'
import MiniStatisticsCard from 'components/Cards/StatisticsCards/MiniStatisticsCard'
import Headerx from 'components/Header/Headerx'
import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography'
import { serverUrl } from 'global/variable'
import TableData from 'myComponent/TableData'
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import userStore from 'store/userStore'
import Swal from 'sweetalert2'

const Detail = () => {
    const logOut = userStore((state) => state.logOut)
    const [tableData, setTableData] = useState([])
    const [deleting, setDeleting] = useState(false)
    const Navigate = useNavigate()
    const location = useLocation()
    const data = location.state

    useEffect(() => {
        console.log(data);
        requestData()
    }, [deleting])

    const requestData = () => {
        axios.get(
            serverUrl + '/desa/' + data.id_kec

            // {
            //     headers: {
            //         'Authorization': `Bearer ${decryptedUser.token}`,
            //         'content-type': 'multipart/form-data'
            //     }
            // }
        )
            .then((response) => {
                // console.log(response);
                setTableData(response.data)
                // setTableLoading(false)
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                // setTableLoading(false)
            })
    }
    return (
        <SoftBox mb={3}>
            <Headerx title="Desa" />
            <Grid container mt={3}>
                <Grid item xs={12}>
                    <MiniStatisticsCard
                        title={{ text: "Total Data" }}
                        count={tableData.length}
                        percentage={{ color: "success", text: "Desa" }}
                        icon={{ color: "info", component: "paid" }}
                    />
                </Grid>
            </Grid>
            <Grid container justifyContent="space-between" mt={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader
                            sx={{mr: 2}}
                            title={
                                <Typography variant='h6'>Kembali</Typography>
                            } avatar={
                                <Tooltip title="Kembali">
                                    <IconButton variant="outlined" onClick={() => Navigate(-1)}><ArrowBack /></IconButton>
                                </Tooltip>
                            }
                            action={
                                <Button
                                    variant="contained"
                                    endIcon={<AddCircleOutline color='white' />}
                                    to="/admin/kecamatan/detail/tambahdesa"
                                    component={Link}
                                    state={data}
                                    // mr={2}
                                >
                                    Tambah
                                </Button>
                            }
                        />
                        <CardContent>
                            {/* <Grid container justifyContent="space-between" mt={2}>
                                <Grid item xs={12}> */}
                                    <TableData
                                        tableData={tableData}
                                        headers={['ID Desa', 'Desa', 'Jumlah DPT', 'DPT Terdata', 'Target Pendataan (KK)', 'KK Terdata']}
                                        fields={['id_desa', 'nama_desa', 'jml_dpt', 'jml_dpt_terdata', 'target', 'kk_terdata']}
                                        deleting={deleting}
                                        setDeleting={setDeleting}
                                        deleteUrl={serverUrl + '/desa/delete'}
                                        deleteField='id_desa'
                                        editUrl={'/editdesa'}
                                    // detailUrl={'/detail'}
                                    />
                                {/* </Grid>
                            </Grid> */}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SoftBox>
    )
}

export default Detail




