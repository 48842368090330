import { Delete, Edit, FileDownload, Info } from '@mui/icons-material'
import { Box, Button, IconButton, Stack, Tooltip } from '@mui/material'
import axios from 'axios';
import { CustomDescrypt } from 'global/func';
import { MaterialReactTable } from 'material-react-table'
import { Link, useLocation } from 'react-router-dom';
import userStore from 'store/userStore';
import Swal from 'sweetalert2';

import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here


const MyTable = ({ data = [], columns, deleting, setDeleting, deleteUrl = null, deleteField, detailUrl = null, editUrl = null, columnVisibility = null, ...props }) => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const pathname = useLocation().pathname;

    // const [listKec, setListKec] = useState([]);

    // useEffect(() => {
    //     if (data.length > 0) {
    //         data.map((row) => (
    //             setListKec(prevState => [...prevState, row.nama_kec])
    //         ))
    //     }
    // }, [data])

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: true,
        // headers: columns.map((c) => c.header),
    };

    const csvExporter = new ExportToCsv(csvOptions);

    const hapusData = (field, key, url) => {
        console.log(key);
        const frm = {}
        frm[field] = key;
        frm['_method'] = 'DELETE'
        Swal.fire({
            title: 'Hapus Data?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Hapus',
            cancelButtonText: 'Batal',
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(
                    url,
                    frm,
                    {
                        headers: {
                            'Authorization': `Bearer ${decryptedUser.token}`,
                            'content-type': 'multipart/form-data'
                        }
                    })
                    .then((response) => {
                        // console.log(response);
                        Swal.fire('Berhasil!', response.data, 'success');
                        setDeleting(!deleting)
                    })
                    .catch((error) => {
                        // console.log(error);
                        if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                            logOut();
                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: error.response.status + ' ' + error.response.statusText
                            })
                        }
                        // setTableLoading(false)
                    })
            }
        })
    }

    // const [columnFilters, setColumnFilters] = useState([]);

    // useEffect(() => {
    //     console.log(columnFilters);
    // }, [columnFilters])

    const handleExportData = () => {
        csvExporter.generateCsv(data);
    };

    const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map((row) => row.original));
    };

    return (
        <MaterialReactTable
            // elevation={0}
            // variant='outlined'
            data={data}
            columns={columns}
            enableColumnResizing
            initialState={{
                showColumnFilters: true,
                columnVisibility: columnVisibility,
                // pagination: { pageSize: 10, pageIndex:1 }
            }}
            enableRowActions
            // onColumnFiltersChange={setColumnFilters} 
            positionActionsColumn='last'
            displayColumnDefOptions={{
                'mrt-row-actions': {
                    header: 'Action', //change header text
                    size: 100, //make actions column wider
                },
            }}
            renderRowActions={({ row }) => (
                <Stack direction="row" spacing={1}>
                    {
                        detailUrl !== null && (
                            <Tooltip title="Detail">
                                <IconButton aria-label="detail" color='info' size='small' component={Link} to={pathname + detailUrl} state={row.original}>
                                    <Info />
                                </IconButton>
                                {/* <IconButton aria-label="detail" color='info' size='small' onClick={() => console.log(row)}>
                                    <Info />
                                </IconButton> */}
                            </Tooltip>
                        )
                    }
                    {
                        editUrl !== null && (
                            <Tooltip title="Edit">
                                <IconButton aria-label="edit" color='info' size='small' component={Link} to={pathname + editUrl} state={row.original}>
                                    <Edit />
                                </IconButton>
                            </Tooltip>
                        )
                    }
                    {
                        deleteUrl !== null && (
                            <Tooltip title="Hapus">
                                <IconButton aria-label="delete" color="error" size='small' onClick={() => hapusData(deleteField, row.original[deleteField], deleteUrl)}>
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                        )
                    }
                </Stack>
            )}
            muiTablePaginationProps={{
                rowsPerPageOptions: [ 10, 20, 50, { label: 'All', value: data.length }],
                showFirstButton: true,
                showLastButton: true,
            }}
            {...props}
            renderTopToolbarCustomActions={({ table }) => (
                <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                >
                    <Button
                        color="primary"
                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                        onClick={handleExportData}
                        startIcon={<FileDownload />}
                        variant="contained"
                    >
                        Export All
                    </Button>
                    <Button
                        disabled={table.getPrePaginationRowModel().rows.length === 0}
                        //export all rows, including from the next page, (still respects filtering and sorting)
                        onClick={() =>
                            handleExportRows(table.getPrePaginationRowModel().rows)
                        }
                        startIcon={<FileDownload />}
                        variant="contained"
                    >
                        Export Rows
                    </Button>
                </Box>
            )}
        />
    )
}

export default MyTable