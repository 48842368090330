import { Card, Grid, Typography } from '@mui/material'
import SoftBox from 'components/SoftBox'
import React from 'react'

const InfoCard = ({ title, icon, count = 17 }) => {
    return (
        <Card>
            <SoftBox p={2}>
                <Grid container alignItems="start" justifyContent='space-between'>
                    <Grid item>
                        <Typography
                            variant='body2'
                            sx={{
                                opacity: 0.8
                            }}
                            color='text'
                        >
                            {title.text}
                        </Typography>
                        <Typography
                            variant="h3"
                            fontWeight="bold"
                        >
                            {count}
                        </Typography>
                    </Grid>
                    <Grid item position='absolute' right={0} top={0}>
                        {icon}
                        {/* <LocationOnOutlined fontSize='medium' color={iconColor} sx={{ opacity: 0.3, fontSize: 90 }} /> */}
                    </Grid>
                </Grid>
            </SoftBox>
        </Card>
    )
}

export default InfoCard
