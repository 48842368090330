import { AddCircleOutline } from '@mui/icons-material'
import { Button, Card, Grid } from '@mui/material'
import axios from 'axios'
import MiniStatisticsCard from 'components/Cards/StatisticsCards/MiniStatisticsCard'
import Headerx from 'components/Header/Headerx'
import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography'
import { CustomDescrypt } from 'global/func'
import { serverUrl } from 'global/variable'
import TableData from 'myComponent/TableData'
import React, { useEffect, useState } from 'react'
import { Link, Route, Routes } from 'react-router-dom'
import userStore from 'store/userStore'
import Swal from 'sweetalert2'
import Tambah from './Tambah'
import Edit from './Edit'
import MyTable from 'myComponent/Table/MyTable'

const Admin = () => {
    return (
        <Routes>
            <Route exact path='' element={<Data />} />
            <Route exact path='tambah' element={<Tambah />} />
            <Route exact path='edit' element={<Edit />} />
        </Routes>
    )
}

const Data = () => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const [admin, setAdmin] = useState([]);
    const [deleting, setDeleting] = useState(false)

    useEffect(() => {
        requestData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleting]);

    const requestData = async () => {
        axios.get(
            serverUrl + '/admin/admin',
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser.token}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                // console.log(response);
                setAdmin(response.data)
                // setTableLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                // setTableLoading(false)
            })
    }

    const columns = [
        //headers={['Username', 'Nama', 'Password', 'Kabupaten', 'Kecamatan', 'Desa']}
        //fields={['username', 'nama', 'password', 'nama_kab', 'nama_kec', 'nama_desa']}

        {
            accessorKey: 'username',
            header: 'Username',
            filterVariant: 'text',
            // filterVariant: 'select',
            // filterSelectOptions: listKec
        },
        {
            accessorKey: 'nama',
            header: 'Nama'
        },
        {
            accessorKey: 'password',
            header: 'Password'
        }
    ]

    return (
        <SoftBox mb={3}>
            <Headerx title='Admin' />
            <Grid container spacing={3} mt={1}>
                <Grid item xs={12}>
                    <MiniStatisticsCard
                        title={{ text: "Total Data" }}
                        count={admin.length}
                        percentage={{ color: "success", text: "Data" }}
                        icon={{ color: "info", component: "paid" }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Card >
                        <Grid container justifyContent="space-between" p={2}>
                            <Grid item xs="auto">
                                <SoftTypography>
                                    Tabel Admin
                                </SoftTypography>
                            </Grid>

                            <Grid item>
                                <Button
                                    variant="contained"
                                    endIcon={<AddCircleOutline color='white' />}
                                    to="/admin/admin/tambah"
                                    component={Link}
                                >
                                    Tambah
                                </Button>
                            </Grid>
                        </Grid>

                        <Grid container justifyContent="space-between" p={2}>
                            <Grid item xs={12}>
                                <MyTable
                                    data={admin}
                                    columns={columns}
                                    deleting={deleting}
                                    setDeleting={setDeleting}
                                    deleteUrl={serverUrl + '/admin/admin/delete'}
                                    deleteField='username'
                                    editUrl={'/edit'}
                                />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </SoftBox>
    )
}

export default Admin

