import { HighchartsReact } from 'highcharts-react-official'
import Highcharts from 'highcharts'

const StackedCollumn = ({title = '', series = [], categories = []}) => {
    const options = {
        chart: {
            type: 'column',
        },
        title: {
            text: title,
            align: 'center'
        },
        xAxis: {
            categories: categories
        },
        yAxis: {
            min: 0,
            visible: false,
            title: {
                text: 'Count trophies'
            },
            stackLabels: {
                enabled: true
            }
        },
        legend: {
            // align: 'left',
            // x: 70,
            // verticalAlign: 'bottom',
            // y: 0,
            // floating: true,
            // backgroundColor:
            //     Highcharts.defaultOptions.legend.backgroundColor || 'white',
            // borderColor: '#CCC',
            // borderWidth: 1,
            // shadow: false
            enabled: true
        },
        credits: {
            enabled: false
        },
        // exporting: {
        //     enabled: false
        // },
        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true
                }
            }
        },
        series: series
    }

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    )
}

export default StackedCollumn