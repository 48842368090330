import { HighchartsReact } from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { useEffect, useState } from 'react'

const ColumnComparison = ({ title = '', kecs, desas }) => {
    const [categories, setCategories] = useState([])
    const [target, setTarget] = useState([]);
    const [tercapai, setTercapai] = useState([]);
    

    useEffect(() => {
        setCategories([])
        setTarget([])
        setTercapai([])
        if (desas.length > 0) {
            // desas.map((data) => {
            //     setCategories(categories => [...categories, data.nama_desa])
            //     setTarget(target => [...target, [data.nama_desa, parseInt(data.target)]])
            //     setTercapai(tercapai => [...tercapai, [data.nama_desa, parseInt(data.kk_terdata)]])
            // })
            desas.forEach(data => {
                setCategories(categories => [...categories, data.nama_desa])
                setTarget(target => [...target, [data.nama_desa, parseInt(data.target)]])
                setTercapai(tercapai => [...tercapai, [data.nama_desa, parseInt(data.kk_terdata)]])
            });
        }
        else{
            // kecs.map((data) => {
            //     setCategories(categories => [...categories, data.nama_kec])
            //     setTarget(target => [...target, [data.nama_kec, parseInt(data.target)]])
            //     // if (data.nama_kec === 'Mandonga') {
            //     //     setTercapai(tercapai => [...tercapai, [data.nama_kec, 5000]])
            //     // }
            //     // else{
            //     //     setTercapai(tercapai => [...tercapai, [data.nama_kec, parseInt(data.kk_terdata)]])
            //     // }
            //     setTercapai(tercapai => [...tercapai, [data.nama_kec, parseInt(data.kk_terdata)]])
            // })
            kecs.forEach(data => {
                setCategories(categories => [...categories, data.nama_kec])
                setTarget(target => [...target, [data.nama_kec, parseInt(data.target)]])
                setTercapai(tercapai => [...tercapai, [data.nama_kec, parseInt(data.kk_terdata)]])
            });
        }
        console.log(kecs.length);
        console.log(desas.length);
        console.log(categories);
        console.log(target);
        console.log(tercapai);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kecs, desas])

    const options = {
        chart: {
            type: 'column'
        },
        // Custom option for templates
        // categories: categories,
        title: {
            text: title,
            align: 'center'
        },
        // subtitle: {
        //     text: 'Comparing to results from Summer Olympics 2016 - Source: <a ' +
        //         'href="https://olympics.com/en/olympic-games/tokyo-2020/medals"' +
        //         'target="_blank">Olympics</a>',
        //     align: 'left'
        // },
        plotOptions: {
            series: {
                grouping: false,
                borderWidth: 0
            }
        },

        legend: {
            enabled: false
        },
        tooltip: {
            shared: true,
            headerFormat: '<span style="font-size: 15px">' +
                '{point.x}' +
                '</span><br/>',
            pointFormat: '<span style="color:{point.color}">\u25CF</span> ' +
                '{series.name}: <b>{point.y} KK</b><br/>'
        },
        xAxis: {
            // type: 'category',
            categories: categories,
            // accessibility: {
            //     description: 'Ca'
            // },
            // labels: {
            //     useHTML: true,
            //     animate: true,
            //     format: '{series.name}<br>' +
            //         '<span class="f32"><span class="flag {value}"></span></span>',
            //     style: {
            //         textAlign: 'center'
            //     }
            // }
        },
        yAxis: [{
            title: {
                text: 'Gold medals'
            },
            showFirstLabel: false
        }],
        series: [{
            color: 'rgba(158, 159, 163, 0.5)',
            pointPlacement: -0.2,
            linkedTo: 'main',
            data: target,
            name: 'Target'
        }, {
            name: 'Tercapai',
            id: 'main',
            // dataSorting: {
            //     enabled: true,
            //     matchByName: true
            // },
            dataLabels: [{
                enabled: true,
                inside: true,
                style: {
                    fontSize: '16px'
                }
            }],
            data: tercapai
        }],
        exporting: {
            allowHTML: true
        }
    }
    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    )
}

export default ColumnComparison