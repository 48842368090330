import { ArrowBack, AttachFile, ExpandMore, Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, CardContent, CardHeader, Checkbox, FormControl, Grid, IconButton, MenuItem, Paper, Select, Tooltip, Typography } from '@mui/material'
import axios from 'axios'
import SoftBox from 'components/SoftBox'
import SoftInput from 'components/SoftInput'
import SoftTypography from 'components/SoftTypography'
import { serverUrl } from 'global/variable'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// import burceMars from "assets/images/bruce-mars.jpg";
import Swal from 'sweetalert2'
import { CustomDescrypt } from 'global/func'
import userStore from 'store/userStore'
import Headerx from 'components/Header/Headerx'
import SelectKab from 'myComponent/SelectKab'
import SelectKec from 'myComponent/SelectKec'
import SelectDesa from 'myComponent/SelectDesa'

const bantuan = [
    'Subsidi Pemerintah',
    'Bantuan Nelayan',
    'Bantuan UMKM',
    'Beasiswa',
    'Bantuan Sosial'
]

const Tambah = () => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        no_kk: '',
        nik_kepala: '',
        nama_kepala: '',
        id_kab: decryptedUser.id_kab,
        nama_kab: decryptedUser.nama_kab,
        id_kec: decryptedUser.id_kec,
        nama_kec: decryptedUser.nama_kec,
        id_desa: decryptedUser.id_desa,
        nama_desa: decryptedUser.nama_desa,
        nama_dusun: '',
        alamat: '',
        pekerjaan: '',
        no_hp: '',
        jml_anggota: '',
        anggota: {},
        lat: '',
        longi: '',
        pln: '-',
        no_pln: '',
        pengguna_pdam: '-',
        pemakaian_pdam: '',
        kelas_bpjs: '-',
        suku: '',
        suku_lain: '',
        bantuan: [],
        src: []
    })

    // const [agg, setAgg] = useState({})

    const getLocation = () => {
        if (!navigator.geolocation) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Geolocation is not supported by your browser'
            })
        } else {
            navigator.geolocation.getCurrentPosition((position) => {
                setFormData({ ...formData, lat: position.coords.latitude, longi: position.coords.longitude });
            }, () => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Unable to retrieve your location'
                })
            });
        }
    }

    const handleBantuanChange = (event) => {
        const {
            target: { value },
        } = event;
        setFormData({
            ...formData,
            bantuan: typeof value === 'string' ? value.split(',') : value
        })
    }

    useEffect(() => {
        
        // getKab()
        getLocation()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        console.log(decryptedUser);
        console.log(formData);
    }, [formData])
    // useEffect(() => {
    //     console.log(agg);
    // }, [agg])

    const loadData = (event) => {
        const files = event.target.files;
        console.log(files.length);
        if (files.length > 0) {
            // let x = [];
            // for (let index = 0; index < files.length; index++) {
            //     x.push(event.target.files[index])
            // }
            // console.log(x);
            setFormData({
                ...formData,
                src: Array.from(event.target.files)
            })
        }
        console.log(formData);
    }
    // const loadData = (event) => {
    //     const file = event.target.files[0];
    //     console.log(file);
    //     console.log(event.target.files);
    //     if (file !== undefined && file !== "") {
    //         setFormData({
    //             ...formData,
    //             src: [...formData.src, file]
    //         })
    //     }
    //     console.log(formData);
    // }


    const submit = (event) => {
        event.preventDefault();
        if (formData.lat === '' || formData.longi === '') {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Izinkan akses lokasi'
            })
            return
        }
        setLoading(true)
        // setFormData({...formData, agg})
        axios.post(
            serverUrl + '/tim/pendataan/insert',
            formData,
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser.token}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                console.log(response);
                const anggGagal = response.data.anggotaGagal;
                const aggl = anggGagal === 0 ? 0 : anggGagal.length;
                const ket = anggGagal === 0 ? '' : ' anggota terdata di keluarga lain (' + anggGagal + ')';
                Swal.fire({
                    icon: 'success',
                    title: 'Berhasil!',
                    text: response.data.msg + ' ' + aggl + ' ' + ket
                })
                navigate(-1)
                setLoading(false)
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.data.messages.msg
                    })
                }
                setLoading(false)
            })
        // setLoading(false)
    }

    const handleChangeAnggota = (event) => {
        // setAgg({...agg, [event.target.name]: event.target.value})
        // setFormData({...formData, agg})
        setFormData({ ...formData, anggota: { ...formData.anggota, [event.target.name]: event.target.value } })
    }

    return (
        <>
            <Headerx title='Tambah Data' />
            <SoftBox mb={3}>
                <Grid container spacing={3} justifyContent='center' py={5}>
                    <Grid item xs={12} sm={12} md={9}>
                        <Card >
                            <CardHeader
                                title={
                                    <SoftTypography variant='h6'>Kembali</SoftTypography>
                                }
                                avatar={
                                    <Tooltip title="Kembali">
                                        <IconButton variant="outlined" onClick={() => navigate(-1)}><ArrowBack /></IconButton>
                                    </Tooltip>
                                }
                            // subheader="September 14, 2016"
                            />
                            <CardContent component='form' onSubmit={submit}>
                                <Grid container spacing={3} justifyContent="center">
                                    <Grid item xs={12} sm={12}>
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Nomor KK
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="number" defaultValue={formData.no_kk} onChange={(event) => setFormData({ ...formData, no_kk: event.target.value })} required />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                NIK Kepala Keluarga
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="number" defaultValue={formData.nik_kepala} onChange={(event) => setFormData({ ...formData, nik_kepala: event.target.value })} required />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Nama Kepala Keluarga
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="text" defaultValue={formData.nama_kepala} onChange={(event) => setFormData({ ...formData, nama_kepala: event.target.value })} required />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SelectKab formData={formData} setFormData={setFormData} disabled={true} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SelectKec formData={formData} setFormData={setFormData} idKab={formData.id_kab} disabled={true} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SelectDesa formData={formData} setFormData={setFormData} idKec={formData.id_kec} disabled={true} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                RT / RW / Dusun
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="text" defaultValue={formData.nama_dusun} onChange={(event) => setFormData({ ...formData, nama_dusun: event.target.value })} required />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Alamat
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="text" defaultValue={formData.alamat} onChange={(event) => setFormData({ ...formData, alamat: event.target.value })} required />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Pekerjaan
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="text" defaultValue={formData.pekerjaan} onChange={(event) => setFormData({ ...formData, pekerjaan: event.target.value })} required />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Nomor HP
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="number" defaultValue={formData.no_hp} onChange={(event) => setFormData({ ...formData, no_hp: event.target.value })} required />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth>
                                            <SoftBox mb={1} ml={0.5}>
                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                    Kelas BPJS
                                                </SoftTypography>
                                            </SoftBox>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={formData.kelas_bpjs}
                                                label="Desa"
                                                onChange={(event) => setFormData({ ...formData, kelas_bpjs: event.target.value })}
                                                required
                                            >
                                                <MenuItem value='-'><em>Tidak Ada</em></MenuItem>
                                                <MenuItem key='1' value='1'>Kelas 1</MenuItem>
                                                <MenuItem key='2' value='2'>Kelas 2</MenuItem>
                                                <MenuItem key='3' value='3'>Kelas 3</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3} justifyContent="center" py={2}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth>
                                            <SoftBox mb={1} ml={0.5}>
                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                    Listrik
                                                </SoftTypography>
                                            </SoftBox>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={formData.pln}
                                                label="Desa"
                                                onChange={(event) => setFormData({ ...formData, pln: event.target.value })}
                                            >
                                                <MenuItem value='-'><em>Tidak Ada</em></MenuItem>
                                                <MenuItem key='1' value='450'>450 WATT</MenuItem>
                                                <MenuItem key='2' value='900'>900 WATT</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {
                                        (formData.pln !== '-') && (
                                            <Grid item xs={12} sm={6}>
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Nomor Meteran Listrik
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftInput type="text" defaultValue={formData.no_pln} onChange={(event) => setFormData({ ...formData, no_pln: event.target.value })} required={formData.pln !== '-'} />
                                            </Grid>
                                        )
                                    }
                                </Grid>
                                <Grid container spacing={3} justifyContent="center" py={2}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth>
                                            <SoftBox mb={1} ml={0.5}>
                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                    Pengguna PDAM
                                                </SoftTypography>
                                            </SoftBox>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={formData.pengguna_pdam}
                                                label="Desa"
                                                onChange={(event) => setFormData({ ...formData, pengguna_pdam: event.target.value })}
                                            >
                                                <MenuItem value='-'><em>Tidak </em></MenuItem>
                                                <MenuItem key='Ya' value='Ya'>Ya</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {
                                        formData.pengguna_pdam !== '-' && (
                                            <Grid item xs={12} sm={6}>
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Pemakaian PDAM (Rp)
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftInput type="number" defaultValue={formData.pemakaian_pdam} onChange={(event) => setFormData({ ...formData, pemakaian_pdam: event.target.value })} required={formData.pengguna_pdam !== "-"} />
                                            </Grid>
                                        )
                                    }
                                </Grid>
                                <Grid container spacing={3} justifyContent="center" py={2}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth>
                                            <SoftBox mb={1} ml={0.5}>
                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                    Suku
                                                </SoftTypography>
                                            </SoftBox>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={formData.suku}
                                                label="Desa"
                                                onChange={(event) => setFormData({ ...formData, suku: event.target.value })}
                                                required
                                            >
                                                <MenuItem value=''><em>Tidak Ada</em></MenuItem>
                                                <MenuItem key='Tolaki' value='Tolaki'>Tolaki</MenuItem>
                                                <MenuItem key='Buton' value='Buton'>Buton</MenuItem>
                                                <MenuItem key='Muna' value='Muna'>Muna</MenuItem>
                                                <MenuItem key='Bugis' value='Bugis'>Bugis</MenuItem>
                                                <MenuItem key='Makassar' value='Makassar'>Makassar</MenuItem>
                                                <MenuItem key='Jawa' value='Jawa'>Jawa</MenuItem>
                                                <MenuItem key='Bali' value='Bali'>Bali</MenuItem>
                                                <MenuItem key='Moronene' value='Moronene'>Moronene</MenuItem>
                                                <MenuItem key='Lainnya' value='Lainnya'>Lainnya</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} alignSelf='flex-end' display={formData.suku !== 'Lainnya' && 'none'}>
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Tuliskan Suku
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput disabled={formData.suku !== 'Lainnya'} type="text" defaultValue={formData.suku === 'Lainnya' ? formData.suku_lain : ''} onChange={(event) => formData.suku === 'Lainnya' && setFormData({ ...formData, suku_lain: event.target.value })} required={formData.suku === 'Lainnya'} />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3} justifyContent="center" py={2}>
                                    <Grid item xs={12} sm={6}>
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Tergolong Penerima Bantuan
                                            </SoftTypography>
                                        </SoftBox>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={formData.bantuan}
                                            label="Desa"
                                            multiple
                                            onChange={handleBantuanChange}
                                            required
                                            renderValue={(selected) => selected.join(', ')}
                                        >
                                            <MenuItem value=''><em>Tidak Ada</em></MenuItem>
                                            {
                                                bantuan.map((bantuanz) => (
                                                    <MenuItem key={bantuanz} value={bantuanz}>
                                                        <Checkbox checked={formData.bantuan.indexOf(bantuanz) > -1} />
                                                        {bantuanz}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </Grid>
                                </Grid>

                                <Grid container justifyContent='center' py={2}>
                                    <Grid item xs={"auto"}>
                                        <Button
                                            variant="outlined"
                                            component="label"
                                            startIcon={<AttachFile />}
                                        >
                                            Dokumentasi
                                            <input
                                                hidden
                                                name='file_spm'
                                                accept="image/jpeg"
                                                type="file"
                                                onChange={loadData}
                                                multiple
                                            />
                                        </Button>
                                    </Grid>
                                </Grid>

                                {formData.src.length > 0 &&
                                    <Grid container spacing={2} py={2} justifyContent="center">
                                        <Grid item xs={10} sx={{ textAlign: "center" }}>
                                            <Accordion>
                                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMore />}>
                                                    <Typography>Dokumentasi</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid container spacing={2} justifyContent='center'>
                                                        {
                                                            formData.src.length > 0 && (
                                                                formData.src.map((gambar, index) => (
                                                                    <Grid item xs={9} key={index} variant='outlined' component={Paper} mb={2}>
                                                                        {/* <IconButton color='error' aria-label="delete" onClick={() => setFormData({ ...formData, src: formData.src.splice(index - 1, 1) })}>
                                                                            <Delete />
                                                                        </IconButton> */}
                                                                        <img
                                                                            src={URL.createObjectURL(gambar)}
                                                                            srcSet={gambar}
                                                                            alt={index}
                                                                            loading="lazy"
                                                                            width='100%'
                                                                        />
                                                                    </Grid>
                                                                ))
                                                            )
                                                        }
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    </Grid>
                                }


                                <Grid container justifyContent='center' py={2}>
                                    <Grid item xs={12} md={6}>
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Jumlah Wajib Pilih (selain kepala keluarga)
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="number" defaultValue={formData.jml_anggota} onChange={(event) => setFormData({ ...formData, jml_anggota: event.target.value })} required />
                                    </Grid>
                                </Grid>
                                {
                                    parseInt(formData.jml_anggota) ? (
                                        Array((parseInt(formData.jml_anggota))).fill(0).map((data, index) => (
                                            <Grid container justifyContent='center' py={2} key={index} spacing={2}>
                                                <Grid item xs={12} md={6}>
                                                    <SoftBox mb={1} ml={0.5}>
                                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                            NIK Anggota ke {index + 1}
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    <SoftInput type="number" name={'nik_anggota_[' + index + ']'} onChange={handleChangeAnggota} required />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <SoftBox mb={1} ml={0.5}>
                                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                            Nama Anggota ke {index + 1}
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    <SoftInput type="text" name={'nama_anggota_[' + index + ']'} onChange={handleChangeAnggota} required />
                                                </Grid>
                                            </Grid>
                                        ))
                                    ) : ''
                                }
                                <Grid container justifyContent='flex-end' py={3}>
                                    <Grid item>
                                        <SoftBox mb={1} ml={0.5}>
                                            <LoadingButton
                                                loading={loading}
                                                loadingPosition="start"
                                                startIcon={<Save />}
                                                variant="contained"
                                                type='submit'
                                            >
                                                Save
                                            </LoadingButton>
                                        </SoftBox>

                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </SoftBox>
        </>
    )
}

export default Tambah