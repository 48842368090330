import { ArrowBack } from '@mui/icons-material'
import { Card, CardContent, CardHeader, Divider, Grid, IconButton, List, ListItem, ListItemText, Tooltip, Typography } from '@mui/material'
import axios from 'axios'
import Headerx from 'components/Header/Headerx'
import SoftBox from 'components/SoftBox'
import { CustomDescrypt } from 'global/func'
import { serverUrl } from 'global/variable'
import MyTable from 'myComponent/Table/MyTable'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import userStore from 'store/userStore'
import Swal from 'sweetalert2'

const Detail = () => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const location = useLocation()
    const data = location.state
    const navigate = useNavigate()
    const [userData, setUserData] = useState([])

    useEffect(() => {
        reqData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const reqData = () => {
        axios.post(
            serverUrl + '/admin/tim/detail',
            {
                nama: data.nama
            },
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser.token}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                console.log(response);
                setUserData(response.data)
                // setTableLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                // setTableLoading(false)
            })
    }

    const columns = [
        //fields = { ['no_kk', 'nik_kepala', 'nama_kepala', 'pekerjaan', 'jml_anggota', 'no_hp', 'created_at', 'nama_petugas']}
        //headers = { ['No KK', 'NIK KK', 'Nama KK', 'Pekerjaan', 'Jml Anggota', 'No. HP', 'Jam Input', 'Tim']}
        {
            accessorKey: 'no_kk',
            header: 'No KK',
            filterVariant: 'text',
            // filterVariant: 'select',
            // filterSelectOptions: listKec
        },
        {
            accessorKey: 'nik_kepala',
            header: 'NIK KK DPT'
        },
        {
            accessorKey: 'nama_kepala',
            header: 'Nama KK'
        },
        {
            accessorKey: 'pekerjaan',
            header: 'Pekerjaan'
        },
        {
            accessorKey: 'jml_anggota',
            header: 'Jml Anggota'
        },
        {
            accessorKey: 'no_hp',
            header: 'No. HP'
        },
        {
            accessorKey: 'created_at',
            header: 'Jam Input'
        },
        {
            accessorKey: 'nama_petugas',
            header: 'Tim'
        },
    ]

    return (
        <SoftBox mb={3}>
            <Headerx title='Detail' />
            <Grid container justifyContent="space-between" p={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader
                            title={
                                <Typography variant='h6'>Kembali</Typography>
                            }
                            avatar={
                                <Tooltip title="Kembali">
                                    <IconButton variant="outlined" onClick={() => navigate(-1)}><ArrowBack /></IconButton>
                                </Tooltip>
                            }
                        // subheader="September 14, 2016"
                        />
                        <CardContent>
                            {/* <Grid container spacing={2} p={1} justifyContent='flex-start'>
                                <Grid item xs={12}>
                                    <SoftTypography variant='h6'>
                                        Username      : {data.username}
                                    </SoftTypography>
                                </Grid>
                            </Grid> */}
                            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                <ListItem>
                                    <ListItemText primary={
                                        <Typography variant='subtitle2'>
                                            Username
                                        </Typography>
                                    } secondary={
                                        <Typography variant='body2'>
                                            {data.username}
                                        </Typography>
                                    } />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                                <ListItem>
                                    <ListItemText primary={
                                        <Typography variant='subtitle2'>
                                            Nama
                                        </Typography>
                                    } secondary={
                                        <Typography variant='body2'>
                                            {data.nama}
                                        </Typography>
                                    } />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                                <ListItem>
                                    <ListItemText primary={
                                        <Typography variant='subtitle2'>
                                            Kabupaten/Kota
                                        </Typography>
                                    } secondary={
                                        <Typography variant='body2'>
                                            {data.nama_kab}
                                        </Typography>
                                    } />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                                <ListItem>
                                    <ListItemText primary={
                                        <Typography variant='subtitle2'>
                                            Kecamatan
                                        </Typography>
                                    } secondary={
                                        <Typography variant='body2'>
                                            {data.nama_kec}
                                        </Typography>
                                    } />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                                <ListItem>
                                    <ListItemText primary={
                                        <Typography variant='subtitle2'>
                                            Kelurahan/Desa
                                        </Typography>
                                    } secondary={
                                        <Typography variant='body2'>
                                            {data.nama_desa}
                                        </Typography>
                                    } />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                            </List>
                            <Grid container spacing={2} justifyContent='center'>
                                <Grid item xs={12}>
                                    <MyTable
                                        data={userData ?? []}
                                        // headers={['No KK', 'NIK KK', 'Nama KK', 'Pekerjaan', 'Jml Anggota', 'No. HP', 'Jam Input', 'Tim']}
                                        // fields={['no_kk', 'nik_kepala', 'nama_kepala', 'pekerjaan', 'jml_anggota', 'no_hp', 'created_at', 'nama_petugas']}
                                        columns={columns}
                                        detailUrl={'/detail-keluarga'}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SoftBox >


    )
}

export default Detail
