import { Card, CardMedia, Grid, Typography } from '@mui/material'
import SoftAvatar from 'components/SoftAvatar'
import SoftBox from 'components/SoftBox'
import React from 'react'

import radhan_foto from "assets/images/radhan_foto.png"
import bg_radhan from "assets/images/bg_radhan.png"
import logo_radhan from "assets/images/logo_radhan.png"
import { CustomDescrypt } from 'global/func'

const Headerx = ({ title }) => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));

    return (
        <SoftBox position="relative" sx={{ paddingTop: '0px !important' }}>
            <SoftBox
                display="grid"
                alignItems="center"
                position="relative"
                minHeight="17rem"
                // borderRadius="xl"
                sx={{
                    backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.info.main, 0),
                            rgba(gradients.info.state, 0)
                        )}, url(${bg_radhan})`,
                    backgroundSize: "cover",
                    backgroundPosition: "50%",
                    overflow: "hidden",
                }}
            >
                <Grid container justifyContent={{ lg: 'flex-start', xs: 'center' }} mt={{ lg: 0, xs: 1 }} >
                    <Grid item ml={{ lg: 5 }}>
                        {/* <SoftBox component={Image} src={giona} alt='giona' width='100%' height='215px' style={{transform: [{scaleX: -1}]}} /> */}
                        {/* <img src={giona} alt='giona' width='100%' height='215px' style={{transform: [{scaleX: -1}]}} /> */}
                        <CardMedia
                            src={radhan_foto}
                            component="img"
                            title={title}
                            sx={{
                                maxWidth: "100%",
                                height: { lg: '215px', xs: '200px' },
                                margin: 0,
                                // boxShadow: ({ boxShadows: { md } }) => md,
                                objectFit: "cover",
                                objectPosition: "center",
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container justifyContent={{ lg: 'center', xs: 'center' }} mt={{ lg: -56, xs: -61 }} width='100%' >
                    <Grid item>
                        <Typography fontWeight={550} color='#0097a7' textAlign='center' fontFamily='cursive' sx={{ fontSize: { xs: 30, lg: 60 } }}>
                            Konsel Kampung Kita
                        </Typography>
                    </Grid>
                </Grid>
            </SoftBox>
            <Card
                sx={{
                    backdropFilter: `saturate(200%) blur(30px)`,
                    backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
                    boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
                    position: "relative",
                    mt: -8,
                    mx: 3,
                    py: 2,
                    px: 2,
                }}
            >
                <SoftBox>
                    <Grid container spacing={2} justifyContent='flex-start'>
                        <Grid item xs='auto' >
                            <SoftAvatar
                                src={logo_radhan}
                                alt="profile-image"
                                variant="rounded"
                                size="xl"
                                shadow="sm"
                            />
                        </Grid>
                        <Grid item>
                            <SoftBox height="100%" my={1} lineHeight={1} width="100%">
                                <Typography variant="h5" color="#0097a7" width="100%">
                                    {/* {decryptedUser.role === '2' ? 'Admin' : 'Tim'} */}
                                    {decryptedUser.nama}
                                </Typography>
                                <Typography variant="body2" width="100%">
                                    {title}
                                </Typography>
                            </SoftBox>
                        </Grid>
                    </Grid>
                </SoftBox>
            </Card>
        </SoftBox>
    )
}

export default Headerx