import Sidenav from 'components/Sidenav'
import React, { useEffect, useState } from 'react'

// import brand from "../../assets/images/logo-ct.png";
import logo_giona from "../../assets/images/logo_giona.png"
import { useSoftUIController } from 'context';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import timRoutes from 'timRoutes';
import { setMiniSidenav } from 'context';
import { setOpenConfigurator } from 'context';
import SoftBox from 'components/SoftBox';
import { Settings } from '@mui/icons-material';
import Configurator from 'components/Configurator';
import DashboardLayout from 'components/DashboardLayout';
import DashboardNavbar from 'components/DashboardNavbar';
import Dashboard from './Dashboard';
import { CustomDescrypt } from 'global/func';
import Pendataan from './Pendataan';

const TemplateTim = () => {
    const navigate = useNavigate();
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const [controller, dispatch] = useSoftUIController();
    const { miniSidenav, direction, openConfigurator, sidenavColor } = controller;
    const [onMouseEnter, setOnMouseEnter] = useState(false);
    const { pathname } = useLocation();

    useEffect(() => {
        if (encryptUser === null || encryptUser === undefined) {
            navigate("/", { replace: true });
        }
        else {
            if (decryptedUser.user === null) {
                navigate("/", { replace: true });
            }
            else {
                if (decryptedUser.role !== '1') {
                    navigate("/", { replace: true });
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [encryptUser, pathname, decryptedUser]);

    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatch, false);
            setOnMouseEnter(true);
        }
    };

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatch, true);
            setOnMouseEnter(false);
        }
    };

    // Change the openConfigurator state
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

    // Setting the dir attribute for the body element
    useEffect(() => {
        document.body.setAttribute("dir", direction);
    }, [direction]);

    // Setting page scroll to 0 when changing the route
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [pathname]);

    const configsButton = (
        <SoftBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="3.5rem"
            height="3.5rem"
            bgColor="white"
            shadow="sm"
            borderRadius="50%"
            position="fixed"
            right="2rem"
            bottom="2rem"
            zIndex={99}
            color="dark"
            sx={{ cursor: "pointer" }}
            onClick={handleConfiguratorOpen}
        >
            <Settings fontSize="default" color="inherit" />
        </SoftBox>
    );

    return (
        <>
            <Sidenav
                color={sidenavColor}
                brand={logo_giona}
                brandName=""
                routes={timRoutes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
                homePage="/tim" />

            <Configurator />
            {configsButton}

            <DashboardLayout>
                {/* {
                    (
                        pathname === '/tim/pendataan/tambah'
                    )
                    ? <Header /> : <DashboardNavbar homePage="/tim" />
                } */}

                <DashboardNavbar homePage="/tim" />

                <Routes>
                    <Route exact path="" element={<Dashboard />} />
                    <Route exact path="pendataan/*" element={<Pendataan />} />

                    {/* <Route exact path='/tim/*' >
                        <Route exact path='' element={<TimList />} />
                        <Route exact path='insert' element={<InsertTim />} />
                        <Route exact path='edit' element={<EditTim />} />
                    </Route>

                    <Route exact path='/admin/*' >
                        <Route exact path='' element={<UserList />} />
                        <Route exact path='insert' element={<InsertUser />} />
                        <Route exact path='edit' element={<EditUser />} />
                    </Route>
                    <Route exact path='/kabupaten' element={<Kabupaten />} /> */}

                </Routes>
            </DashboardLayout>
        </>
    )
}

export default TemplateTim