import { Delete, Edit, Info } from '@mui/icons-material'
import { IconButton, Stack, Table, TableBody, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Tooltip } from '@mui/material'
import axios from 'axios'
import { TablePaginationActions } from 'global/func'
import { CustomDescrypt } from 'global/func'
import { StyledTableRow } from 'global/func'
import { StyledTableCell } from 'global/func'
import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import userStore from 'store/userStore'
import Swal from 'sweetalert2'

const TableData = ({ tableData, headers = [], fields = [], deleting, setDeleting, deleteUrl = null, deleteField, detailUrl = null, editUrl = null }) => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const pathname = useLocation().pathname;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const hapusData = (field, key, url) => {
        console.log(key);
        const frm = {}
        frm[field] = key;
        frm['_method'] = 'DELETE'
        Swal.fire({
            title: 'Hapus Data?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Hapus',
            cancelButtonText: 'Batal',
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(
                    url,
                    frm,
                    {
                        headers: {
                            'Authorization': `Bearer ${decryptedUser.token}`,
                            'content-type': 'multipart/form-data'
                        }
                    })
                    .then((response) => {
                        // console.log(response);
                        Swal.fire('Berhasil!', response.data, 'success');
                        setDeleting(!deleting)
                    })
                    .catch((error) => {
                        // console.log(error);
                        if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                            logOut();
                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: error.response.status + ' ' + error.response.statusText
                            })
                        }
                        // setTableLoading(false)
                    })
            }
        })
    }

    return (
        <TableContainer>
            <Table aria-label="collapsible table" sx={{ minWidth: 500 }}>
                <TableHead>
                    <TableRow>
                        {
                            headers.map((header, index) => (
                                <StyledTableCell key={index}>{header}</StyledTableCell>
                            ))
                        }
                        <StyledTableCell align='center'>Aksi</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        tableData.length > 0 ?
                            (rowsPerPage > 0
                                ? tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : tableData
                            ).map((row) => (
                                <StyledTableRow
                                    key={row[fields[0]]}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    {
                                        fields.map((field, index) => (
                                            <StyledTableCell key={index}>{row[field]}</StyledTableCell>
                                        ))
                                    }
                                    <StyledTableCell width={2} align='center'>
                                        <Stack direction="row" spacing={1}>
                                            {
                                                detailUrl !== null && (
                                                    <Tooltip title="Detail">
                                                        <IconButton aria-label="detail" color='info' size='small' component={Link} to={pathname + detailUrl} state={row}>
                                                            <Info />
                                                        </IconButton>
                                                    </Tooltip>
                                                )
                                            }
                                            {
                                                editUrl !== null && (
                                                    <Tooltip title="Edit">
                                                        <IconButton aria-label="edit" color='info' size='small' component={Link} to={pathname + editUrl} state={row}>
                                                            <Edit />
                                                        </IconButton>
                                                    </Tooltip>
                                                )
                                            }
                                            {
                                                deleteUrl !== null && (
                                                    <Tooltip title="Hapus">
                                                        <IconButton aria-label="delete" color="error" size='small' onClick={() => hapusData(deleteField, row[deleteField], deleteUrl)}>
                                                            <Delete />
                                                        </IconButton>
                                                    </Tooltip>
                                                )
                                            }
                                        </Stack>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))
                            :
                            <StyledTableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <StyledTableCell component="th" scope="row" colSpan={headers.length + 1}>
                                    <em>Tidak ada data</em>
                                </StyledTableCell>
                            </StyledTableRow>
                    }
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[20, 50, 100, { label: 'All', value: -1 }]}
                            count={tableData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    )
}

export default TableData